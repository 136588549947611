import { inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { User } from '@core/models/entities/User/User';
import { CookieService } from 'ngx-cookie-service';
import { ApiHttpService, CustomHttpResponse } from '@core/services/api-http.service';
import { UserSettings } from '@core/models/entities/User/UserSettings';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #cookieService = inject(CookieService);
  #apiService = inject(ApiHttpService);

  getUserMe(): Observable<User> {
    return this.#apiService.get('/users/me').pipe(
      map((result: CustomHttpResponse<User>) => result.body as User),
      tap((user) => this.#setMercureCookie(user)),
      map((user) => user),
    );
  }

  updateUserMeSettings(user: User, userSettings: UserSettings): Observable<UserSettings> {
    return this.#apiService
      .put(`/users/${user.id}/settings`, userSettings)
      .pipe(map((result: CustomHttpResponse<UserSettings>) => result.body as UserSettings));
  }

  #setMercureCookie(user: User) {
    if (user.mercureSubscribeToken) {
      this.#cookieService.set('mercureAuthorization', user.mercureSubscribeToken.token, {
        expires: new Date(user.mercureSubscribeToken.expiresAt),
        secure: true,
        sameSite: 'Strict',
        path: '/api/.well-known/mercure',
      });
    }
  }
}
